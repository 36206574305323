import {
	Button,
	Card,
	CardContent,
	Container,
	Stack,
	Avatar,
	Typography,
} from '@mui/material';
import axios from '../../../services/auth-header.js';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const InviteCard = ({ invitation, openDenyModal }) => {
	const getGoogleMapsLink = (address) =>
		`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
			address
		)}`;
	const [survey, setSurvey] = useState(null);
	const [restaurant, setRestaurant] = useState(null);
	const navigate = useNavigate();
	const createSurveyResponse = async () => {
		try {
			const surveyResponse = {
				surveyId: invitation.surveyId,
				dinerId: invitation.dinerId,
				restaurantId: invitation.restaurantId,
				expirationDate: invitation.expirationDate,
			};
			const result = await axios.post(
				`/survey/response/create`,
				surveyResponse
			);
			if (result) {
				navigate(`/survey/respond`);
			}
		} catch (error) {
			console.error('ERROR CREATING SURVEY RESPONSE:\n' + error);
		}
	};

	const accept = async () => {
		try {
			const result = await axios.post(
				`/survey/invite/accept/${invitation.surveyInvitationId}`
			);
			if (result) {
				await createSurveyResponse();
			}
		} catch (error) {
			console.log('ERROR ACCEPTING SURVEY:\n' + error);
		}
	};

	const getSurvey = async () => {
		try {
			const result = await axios.get(`survey/${invitation.surveyId}`);
			const surveyResponse = result.data;
			if (!surveyResponse) {
				setSurvey(null);
			} else {
				setSurvey(result.data);
			}
		} catch (error) {
			console.error('ERROR FETCHING SURVEY:\n' + error);
		}
	};

	const getRestaurant = async () => {
		try {
			const result = await axios.get(`restaurant/${invitation.restaurantId}`);
			const restaurantResponse = result.data;
			if (!restaurantResponse) {
				setRestaurant(null);
			} else {
				setRestaurant(result.data);
			}
		} catch (error) {
			console.error('ERROR FETCHING RESTAURANT:\n' + error);
		}
	};

	useEffect(() => {
		getRestaurant();
		getSurvey();
	}, []);

	return (
		<Card
			variant='outlined'
			className='w-full overflow-visible py-4'
			style={{ maxWidth: '32rem' }}>
			<CardContent>
				<Container>
					<Stack>
						<Stack direction='row' spacing={4}>
							{restaurant && (
								<Avatar
									alt={`${restaurant.restaurantName} Logo`}
									src={
										process.env.REACT_APP_S3_BUCKET_URL + restaurant?.logoPath
									}
									sx={{ width: 72, height: 72 }}
								/>
							)}
							<Stack>
								<Typography variant='smTitle'>
									{restaurant && restaurant.restaurantName}
								</Typography>
								<Typography variant='md'>
									{restaurant && (
										<a
											href={getGoogleMapsLink(
												`${restaurant.streetAddress} ${restaurant.city}, ${restaurant.stateAbbreviation} ${restaurant.zipCode}`
											)}
											target='_blank'
											rel='noopener noreferrer'>
											{`${restaurant.streetAddress} ${restaurant.city}, ${restaurant.stateAbbreviation} ${restaurant.zipCode}`}
										</a>
									)}
								</Typography>
								<Typography variant='hsm' className='text-[#999999]'>
									4.3 miles away
								</Typography>
							</Stack>
						</Stack>
						<div className='border-[#C6C6C6] border-[0.5px] my-4'></div>
						<Stack direction='row' spacing={8}>
							<Stack spacing={0.25}>
								<Typography variant='xsm' className='font-semibold'>
									Rewards
								</Typography>
								<Typography variant='sm'>
									{`$${survey?.rewardAmount} value`}
								</Typography>
								<Typography variant='xsm' className='text-[#999999]'>
									Support by Tremendous
								</Typography>
							</Stack>
							<Stack spacing={0.25}>
								<Typography variant='xsm' className='font-semibold'>
									Length of the Survey
								</Typography>
								<Typography variant='sm'>
									{`${survey?.questions?.length} Questions`}
								</Typography>
								<Typography variant='xsm' className='text-[#999999]'>
									{`Estimate ${survey?.questions?.length} minutes`}
								</Typography>
							</Stack>
						</Stack>
						{/* Actually get a tailored description */}
						<Typography variant='sm' className='text-[#555555] mt-6'>
							The survey contains multiple choice, long/short typing, and photo
							taking, so we suggest you to start the survey upon the arrival of
							the restaurant.
						</Typography>
						<div className='w-full flex justify-center mt-6 space-x-12'>
							<Button
								className='w-[7.938rem] h-[2.75rem]'
								variant='outlined'
								onClick={() => openDenyModal(invitation.surveyInvitationId)}>
								Reject
							</Button>
							<Button
								className='w-[7.938rem] h-[2.75rem]'
								variant='contained'
								onClick={accept}>
								Accept
							</Button>
						</div>
					</Stack>
				</Container>
			</CardContent>
		</Card>
	);
};

export default InviteCard;

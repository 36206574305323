import { useAppContext } from '../../../context/AppContext';
import {
	Card,
	CardContent,
	IconButton,
	CardActions,
	Container,
	Menu,
	MenuItem,
	ListSubheader,
	Tooltip,
	Stack,
	Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import axios from '../../../services/auth-header';
import { useEffect, useState } from 'react';

const QuestionCard = ({
	question,
	children,
	onDelete,
	isHiddenQuestion,
	setIsHiddenQuestion,
	setIsHiddenBranchOption,
}) => {
	const { user } = useAppContext();
	const [anchorEl, setAnchorEl] = useState(null);
	const [isBranchableQuestion, setBranchableQuestion] = useState(false);
	const handleDelete = async (event) => {
		event.stopPropagation();
		try {
			const response = await axios.delete(
				`question/delete/${question.questionId}`
			);
			onDelete();
		} catch (err) {
			console.error(err);
		}
	};

	const handleMenuOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	const getConditionalQuestionResponse = async (questionId) => {
		try {
			const response = await axios.get(
				`question/response/${question.questionId}/${user.userId}`
			);
			return response.data;
		} catch (err) {
			console.error(err);
		}
	};

	const handleBranchableQuestion = () => {
		if (
			question.questionType === 'MULTIPLE_CHOICE' ||
			question.questionType === 'YES_NO'
		) {
			setBranchableQuestion(true);
		} else {
			setBranchableQuestion(false);
		}
	};

	const handleAddBranchOption = () => {
		handleMenuClose();
		setIsHiddenBranchOption(question.questionId, false);
	};

	useEffect(() => {
		const fetchData = async () => {
			var newVisibility = question.conditionalQuestion && !user.admin;

			if (question.conditionalQuestion && !user.admin) {
				try {
					const questionResponse = await getConditionalQuestionResponse(
						question.conditionalQuestion
					);
					if (
						!(
							questionResponse.numberResponse === null &&
							questionResponse.textResponseShort === null &&
							questionResponse.textResponseLong === null
						)
					) {
						newVisibility = false;
					}
				} catch (err) {
					console.error(err);
				}
			}

			if (newVisibility !== isHiddenQuestion) {
				setIsHiddenQuestion(question.questionId, newVisibility);
			}
		};
		fetchData();
		handleBranchableQuestion();
	}, []);

	return (
		<Card
			variant='outlined'
			className='w-full overflow-visible lg:max-w-[70%]'
			style={{
				display: isHiddenQuestion ? 'none' : 'block',
			}}
			sx={{
				boxShadow:
					'0px 2px 4px rgba(0, 0, 0, 0.1), 0px 3px 6px rgba(0, 0, 0, 0.15)',
			}}>
			<CardContent>
				<Stack spacing={1}>
					<Typography className='text-[#222222]' variant='qText'>
						{question.questionText}
					</Typography>
					{children}
				</Stack>
			</CardContent>
			{user.admin && (
				<CardActions className='flex justify-end'>
					{user.admin && isBranchableQuestion && (
						<>
							<Tooltip title='More Options'>
								<IconButton onClick={handleMenuOpen}>
									<MoreVertIcon />
								</IconButton>
							</Tooltip>
							<Menu
								id='simple-menu'
								anchorEl={anchorEl}
								keepMounted
								open={Boolean(anchorEl)}
								onClose={handleMenuClose}>
								<ListSubheader>Options</ListSubheader>
								<MenuItem onClick={handleAddBranchOption}>
									Go to question based on answer
								</MenuItem>
							</Menu>
						</>
					)}
					{user.admin && (
						<CardActions className='flex justify-end'>
							<IconButton onClick={handleDelete}>
								<DeleteIcon />
							</IconButton>
						</CardActions>
					)}
				</CardActions>
			)}
		</Card>
	);
};

export default QuestionCard;

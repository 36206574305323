import {
	Button,
	Card,
	CardContent,
	Container,
	Stack,
	Avatar,
	Typography,
} from '@mui/material';
import axios from '../../../services/auth-header.js';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DescriptionOutlined, TimerOutlined } from '@mui/icons-material';

const SurveyCard = ({ survey }) => {
	const [restaurant, setRestaurant] = useState(null);
	const [logoPath, setLogoPath] = useState('');
	const navigate = useNavigate();

	const getGoogleMapsLink = (address) =>
		`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
			address
		)}`;
	const daysLeft = () =>
		Math.ceil(
			(new Date(survey.expirationDate).getTime() - new Date().getTime()) /
				(1000 * 3600 * 24)
		);
	const getRestaurant = async () => {
		try {
			const result = await axios.get(`restaurant/${survey.restaurantId}`);
			const restaurantResponse = result.data;
			if (!restaurantResponse) {
				setRestaurant(null);
			} else {
				setRestaurant(result.data);
				setLogoPath(
					process.env.REACT_APP_S3_BUCKET_URL + restaurantResponse.logoPath
				);
			}
		} catch (error) {
			console.error('ERROR FETCHING RESTAURANT:\n' + error);
		}
	};

	useEffect(() => {
		getRestaurant();
	}, []);

	return (
		<Card
			variant='outlined'
			className='w-full overflow-visible py-4'
			style={{ maxWidth: '32rem' }}>
			<CardContent>
				<Container>
					<Stack>
						<Stack direction='row' spacing={4}>
							{restaurant && (
								<Avatar
									alt={`${restaurant.restaurantName} Logo`}
									src={
										process.env.REACT_APP_S3_BUCKET_URL + restaurant?.logoPath
									}
									sx={{ width: 72, height: 72 }}
								/>
							)}
							<Stack>
								<Typography variant='smTitle'>
									{restaurant && restaurant.restaurantName}
								</Typography>
								<Typography variant='md'>
									{restaurant && (
										<a
											href={getGoogleMapsLink(
												`${restaurant.streetAddress} ${restaurant.city}, ${restaurant.stateAbbreviation} ${restaurant.zipCode}`
											)}
											target='_blank'
											rel='noopener noreferrer'>
											{`${restaurant.streetAddress} ${restaurant.city}, ${restaurant.stateAbbreviation} ${restaurant.zipCode}`}
										</a>
									)}
								</Typography>
								<Typography variant='hsm' className='text-[#999999]'>
									4.3 miles away
								</Typography>
							</Stack>
						</Stack>
						<div className='border-[#C6C6C6] border-[0.5px] my-4'></div>
						<Stack direction='row' spacing={8}>
							<Stack spacing={0.25}>
								<Typography variant='xsm' className='font-semibold'>
									Rewards
								</Typography>
								<Typography variant='sm'>
									{`$${survey?.survey?.rewardAmount} value`}
								</Typography>
								<Typography variant='xsm' className='text-[#999999]'>
									Support by Tremendous
								</Typography>
							</Stack>
							<Stack spacing={0.25}>
								<Typography variant='xsm' className='font-semibold'>
									Length of the Survey
								</Typography>
								<Typography variant='sm'>
									{`${survey?.survey?.questions?.length} Questions`}
								</Typography>
								<Typography variant='xsm' className='text-[#999999]'>
									{`Estimate ${survey?.survey?.questions?.length} minutes`}
								</Typography>
							</Stack>
						</Stack>
						{/* Actually get a tailored description */}
						<Typography variant='sm' className='text-[#555555] mt-6'>
							The survey contains multiple choice, long/short typing, and photo
							taking, so we suggest you to start the survey upon the arrival of
							the restaurant.
						</Typography>

						<div className='w-full flex justify-center mt-6 space-x-12'>
							<Button
								className='px-4 py-2 w-full flex space-x-4'
								variant='outlined'
								sx={{
									borderRadius: '50px',
									padding: '10px 20px',
									color: 'black',
								}}
								onClick={() => navigate('/survey/respond')}
								startIcon={<DescriptionOutlined />}>
								<Stack direction='row' className='justify-between w-full'>
									<Typography variant='md' noWrap>
										Complete survey now
									</Typography>
									<Typography
										variant='md'
										noWrap
										sx={{ fontSize: '0.5rem', color: '#0D9A0A' }}>
										{daysLeft()} days left
									</Typography>
								</Stack>
							</Button>
						</div>
					</Stack>
				</Container>
			</CardContent>
		</Card>
	);
};

export default SurveyCard;

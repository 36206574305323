import {
	Stack,
	TextField,
	Typography,
	Button,
	IconButton,
	Divider,
} from '@mui/material';
import { useState, useEffect } from 'react';
import { useAppContext } from '../../../context/AppContext.jsx';
import axios from '../../../services/auth-header.js';
import debounce from '../../../utils/debounce.js';
import Link from '@mui/material/Link';
import { VisuallyHiddenInput } from '../../common/VisuallyHiddenInput.jsx';
import UploadPhotoIcon from './UploadPhotoIcon.png';
import TakePhotoIcon from './TakePhotoIcon.png';

const bucketUrl = process.env.REACT_APP_S3_BUCKET_URL;

const PictureResponse = ({ question }) => {
	const [questionText, setQuestionText] = useState(question.questionText || '');
	const [isCreate, setIsCreate] = useState(true);
	const [imagePath, setImagePath] = useState(
		question.questionResponses?.[0]?.imagePath || 0
	);

	const { user } = useAppContext();

	useEffect(() => {
		setIsCreate(user.admin);
	}, []);

	const handleQuestionTextChange = async (e) => {
		const newText = e.target.value;
		setQuestionText(newText);
		question.questionText = newText;
		debounce(async () => {
			try {
				await axios.post('question/save', {
					...question,
					newQuestionText: newText,
				});
			} catch (err) {
				console.log(err);
			}
		})();
	};

	const photoCaptured = async (e) => {
		const file = e.target.files[0];
		const formData = new FormData();
		formData.append('image', file);
		try {
			const response = await axios.post('image/create', formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			});

			const newImagePath = response.data.imagePath;

			setImagePath(newImagePath);

			question.imagePath = newImagePath;

			await axios.post('question/response/save', {
				...question,
				imagePath: newImagePath,
				dinerId: question.dinerId,
				surveyResponseId: question.surveyResponseId,
				questionResponseId: question.questionResponseId,
			});
		} catch (err) {
			console.error(err);
		}
	};

	return (
		<Stack spacing={2}>
			{isCreate && (
				<TextField
					className='question-text'
					value={questionText}
					onChange={handleQuestionTextChange}
					variant='outlined'
					fullWidth
					multiline
					placeholder={'Enter your question here'}
				/>
			)}
			{!!imagePath && (
				<Link
					href={bucketUrl + imagePath}
					target='_blank'
					rel='noreferrer'
					className='h-8'>
					{imagePath ? 'capture.png' : ''}
				</Link>
			)}
			<Stack
				direction='row'
				className=' justify-around'
				divider={<Divider orientation='vertical' flexItem />}>
				<Stack alignItems='center'>
					<IconButton
						component='label'
						role={undefined}
						variant='contained'
						tabIndex={-1}>
						<img src={TakePhotoIcon} alt='Upload' size='40px' />
						<VisuallyHiddenInput
							type='file'
							accept='image/*'
							capture='environment'
							onChange={photoCaptured}
						/>
					</IconButton>
					<Typography variant='xmd' className='text-[#686868]'>
						Take a photo
					</Typography>
				</Stack>
				<Stack alignItems='center'>
					<IconButton
						component='label'
						role={undefined}
						variant='contained'
						tabIndex={-1}>
						<img src={UploadPhotoIcon} alt='Upload' size='40px' />
						<VisuallyHiddenInput
							type='file'
							accept='image/*'
							onChange={photoCaptured}
						/>
					</IconButton>
					<Typography variant='xmd' className='text-[#686868]'>
						Upload a photo
					</Typography>
				</Stack>
			</Stack>
		</Stack>
	);
};

export default PictureResponse;

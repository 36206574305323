import { IconButton, Stack, TextField, Typography } from '@mui/material';
import { useState, useEffect, setState } from 'react';
import { useAppContext } from '../../../context/AppContext.jsx';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import axios from '../../../services/auth-header.js';
import debounce from '../../../utils/debounce.js';

const TimerResponse = ({ question }) => {
	const [questionText, setQuestionText] = useState(question.questionText || '');
	const [started, setStarted] = useState(false);
	const [finished, setFinished] = useState(false);
	const [isCreate, setIsCreate] = useState(true);
	const [startTime, setStartTime] = useState(null);
	const [time, setTime] = useState(
		question.questionResponses?.[0]?.numberResponse || 0
	);

	const { user } = useAppContext();

	const handleQuestionTextChange = async (e) => {
		const newText = e.target.value;
		setQuestionText(newText);
		question.questionText = newText;
		debounce(async () => {
			try {
				await axios.post('question/save', {
					...question,
					newQuestionText: newText,
				});
			} catch (err) {
				console.log(err);
			}
		})();
	};

	const setTimeResponse = async () => {
		try {
			await axios.post('question/response/save', {
				...question,
				numberResponse: time,
				dinerId: question.dinerId,
				surveyResponseId: question.surveyResponseId,
				questionResponseId: question.questionReponseId,
			});
			return true;
		} catch (err) {
			console.error(err);
			return false;
		}
	};

	const startTimer = () => {
		setStartTime(Date.now());
		setStarted(true);
	};

	const resetTimer = () => {
		setStarted(false);
		setFinished(false);
		setStartTime(null);
		setTime(0);
	};

	const stopTimer = async () => {
		let success = await setTimeResponse();
		if (success) {
			setFinished(true);
		} else {
			alert('Failed to save time');
			setFinished(true);
		}
		setStartTime(null);
	};

	useEffect(() => {
		let interval = null;

		if (started && !finished) {
			interval = setInterval(() => {
				let now = Date.now();
				let newTime = now - startTime;
				setTime(newTime);
			}, 100);
		} else {
			clearInterval(interval);
		}
		return () => {
			clearInterval(interval);
		};
	}, [started, finished]);

	useEffect(() => {
		setIsCreate(user.admin);
		if (time === 0) {
			setFinished(false);
		} else {
			setFinished(true);
			setStarted(true);
		}
	}, []);

	return isCreate ? (
		<Stack spacing={2}>
			<TextField
				className='question-text'
				value={questionText}
				onChange={handleQuestionTextChange}
				variant='outlined'
				fullWidth
				multiline
				placeholder={'Enter your question here'}
			/>
			<Typography fontSize='3.75rem'>
				{('0' + Math.floor((time / 60000) % 60)).slice(-2)}:
				{('0' + Math.floor((time / 1000) % 60)).slice(-2)}
			</Typography>
			<div>
				<IconButton
					disabled={finished || isCreate}
					onClick={!started && !finished ? startTimer : stopTimer}>
					{!started && !finished ? (
						<PlayCircleFilledWhiteIcon
							color={isCreate ? 'grey' : 'info'}
							sx={{ fontSize: 50 }}
						/>
					) : !finished ? (
						<StopCircleIcon
							color={finished ? 'grey' : 'error'}
							sx={{ fontSize: 50 }}
						/>
					) : (
						<CheckCircleIcon sx={{ fontSize: 50 }} />
					)}
				</IconButton>
				<IconButton disabled={!started || isCreate} onClick={resetTimer}>
					<RestartAltIcon
						sx={{ fontSize: 50 }}
						color={started ? 'info' : 'grey'}
					/>
				</IconButton>
			</div>
		</Stack>
	) : (
		<Stack>
			<Typography className='text-[#555555] -mt-2' variant='sm'>
				You can restart it or manually input the time.
			</Typography>
			<Stack direction='row' className='justify-between'>
				<Typography variant='xlg'>
					{('0' + Math.floor((time / 60000) % 60)).slice(-2)}:
					{('0' + Math.floor((time / 1000) % 60)).slice(-2)}
				</Typography>
				<Stack direction='row'>
					<IconButton
						disabled={finished || isCreate}
						onClick={!started && !finished ? startTimer : stopTimer}>
						{!started && !finished ? (
							<PlayCircleFilledWhiteIcon
								className='text-[#FFC804]'
								sx={{ fontSize: 50 }}
							/>
						) : !finished ? (
							<StopCircleIcon
								color={finished ? 'grey' : 'error'}
								sx={{ fontSize: 50 }}
							/>
						) : (
							<CheckCircleIcon sx={{ fontSize: 50 }} />
						)}
					</IconButton>
					<IconButton disabled={!started || isCreate} onClick={resetTimer}>
						<RestartAltIcon
							sx={{ fontSize: 50 }}
							color={started ? 'info' : 'grey'}
						/>
					</IconButton>
				</Stack>
			</Stack>
		</Stack>
	);
};

export default TimerResponse;
